import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'octane-spark';

import copy from '../copy.json';

export const Model = ({ value, make, options, disabled, onChange }) => {
    const handleSelection = (modelId) => {
        if (modelId === 'none') return onChange({ id: 'none', name: 'none' });
        const modelSelected = options.find((option) => option.id === modelId);
        onChange({
            id: modelSelected.id,
            name: modelSelected.name,
            price: modelSelected.price,
            msrp: modelSelected.msrp,
            vehicle_type: modelSelected.vehicle_type,
        });
    };

    const emptyModels =
        make !== null && make !== 'none' && options.length === 0 && !disabled;

    return (
        <Select
            id="select-model"
            label={copy.fields.model.label}
            placeholder={copy.fields.model.placeholder}
            value={value}
            disabled={disabled || emptyModels}
            onChange={handleSelection}
        >
            <Select.Option key="none" value="none">
                {!emptyModels && copy.fields.model.notSure}
                {emptyModels && copy.fields.model.notModels}
            </Select.Option>
            {options.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                    {option.name}
                </Select.Option>
            ))}
        </Select>
    );
};

Model.propTypes = {
    value: PropTypes.string,
    make: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
